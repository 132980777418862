.react-datepicker {
    height: 100%;
    width: 100%;
    border: none;
    font-size: 14px;
}

.react-datepicker__month-container {
    width: 100%;
    height: 100%;
}

.react-datepicker__header {
    margin-bottom: 15px;
    padding: 0px;
    background-color: transparent;
    border: none;
    text-align: start;
}

.react-datepicker__day-names {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.react-datepicker__day-name {
    color: #839D9F;
    font-size: 10px;
}

.react-datepicker__month {
    text-align: start;
    margin: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media (max-width: 1024px) {
    .react-datepicker__month {
        gap: 5px;
    }
}

.react-datepicker__week {
    display: flex;
    justify-content: space-between;
}


.react-datepicker__day{
    color: #24595C;
}

.react-datepicker__day--disabled {
    color: #BDCBCC;
}

.react-datepicker__day--selected {
    background-color: transparent;
    color: #DF3246;
    font-weight: bold;
}

.react-datepicker__day--selected:hover {
    background-color: #f0f0f0;
}

.react-datepicker__day--outside-month {
    color: #BDCBCC;
    pointer-events: none;
}
