.clicked .shopping-cart {
    animation: cart 1.5s ease-in-out forwards;
}

.clicked .shopping-box {
    animation: box 1.5s ease-in-out forwards;
}

.clicked .add-to-cart-label {
    animation: addToCartLabel 1.5s ease-in-out forwards;
}

.clicked .added-label {
    animation: addedLabel 1.5s ease-in-out forwards;
}

@keyframes cart {
    0% {
        left: -10%;
    }

    40%,
    60% {
        left: 50%;
    }

    100% {
        left: 110%;
    }
}

@keyframes box {

    0%,
    40% {
        top: -20%;
    }

    60% {
        top: 40%;
        left: 50%;
    }

    100% {
        top: 40%;
        left: 112%;
    }
}

@keyframes addToCartLabel {
    0% {
        opacity: 1;
    }

    20%,
    100% {
        opacity: 0;
    }
}

@keyframes addedLabel {

    0%,
    80% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}